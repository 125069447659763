@import "../../../../../../styles/variables";
.create-user-form-container{
  height: 85vh;
  .Toastify__progress-bar--default{
    background: none;
  }
  .toast{
    position: relative;
    top: 100px;
    border-radius: 4px;
    background-color: $color-white;
    font-family: Montserrat;
    font-size: 14px;
    color: $color-black;
    font-weight: 600;
    padding-left: 1em;
  }

  .hr-style{
    margin: 0;
  }
  .flex-wrapper{
    display: flex;
  }

  .create-user-wrapper{
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
    margin-top: 4%;
    .width-equal {
      width: 50%;
    }
    .white-container{
      background: #ffffff;
      padding: 3%;
        hr{
          margin: 1% 0 3% 0;
        }
      .title{
        font-size: 20px;
        color: #616161;
      }

      .flex-container {
        display: flex;
      }
      .success-created{
        width: 90%;
        background: url("../../../../../../styles/images/user.png") 70% 40% no-repeat;
        text-align: center;
        .success-block{
          width: 50%;
          margin:  25% auto;
        }
        span{
          margin-right: 1%;
        }

        .bold{
          font-weight: 700;
        }
      }
    }
  }
}
