@import "../../../../../../../styles/variables";
.profile-menu{
  .invisible{
    display: none;
  }
  .select-menu{
    display: flex;
    flex-direction: column;
    background: $color-white;
    position: absolute;
    right: 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
    margin-top: 15px;
    margin-right: 115px;
    z-index: 9;
    font-size: 13px;

    &:before{
      content:'';
      position: absolute;
      top: -0.9em;
      left: 80%;
      width: 0;
      height: 0;
      border-bottom: solid 12px $color-white;
      border-left: solid 12px transparent;
      border-right: solid 12px transparent;
    }

    .menu-item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 200px;
      border: none;
      background: $color-white;
      color: $color-black;
      font-weight: 600;
      font-family: Montserrat;
      cursor: pointer;
    }
    .logout{
      &:before{
        content: '';
        width: 35px;
        height: 30px;
        background: url("../../../../../../../styles/images/logout-black.svg") no-repeat center;
      }
      &:hover{
        background: url("../../../../../../../styles/images/logout-white.svg") $color-violet no-repeat 0% 30%;
        color: $color-white;
        &:before{
          visibility: hidden;
        }
      }
    }
    .account{
      text-decoration: none;
      &:before{
        content: '';
        width: 40px;
        height: 30px;
        background: url("../../../../../../../styles/images/account-black.svg") no-repeat center;
      }
      &:hover{
        background: url("../../../../../../../styles/images/account.png") $color-violet no-repeat 0% 30%;
        color: $color-white;
        &:before{
          visibility: hidden;
        }
      }
    }
  }
  .logout-menu{
    width: 60px;
    height: 100%;
    background: url("../../../../../../../styles/images/account.png") no-repeat center $color-violet;
    border: none;
    cursor: pointer;
    &:hover {
      background-color: $color-hover-btn;
      transition-duration: 0.2s;
    }
  }
}
