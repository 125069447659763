@import "../../../styles/variables";

.input-box {
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 15px;
    height: 18px;
    font-size: $small-font-size;
    letter-spacing: 1px;
    color: $color-black;
  }
  input {
    padding: 16px;
    background-color: #f4f4f4;
    border: 0;
    font-size: $small-font-size;
    font-style: italic;
    letter-spacing: 0.73px;
  }
  .error {
    margin-top: 10px;
    height: 18px;
    font-size: $small-font-size;
    letter-spacing: 0.73px;
    color: #ff0000;
  }
}
