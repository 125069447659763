@import "../../../../styles/variables";

.payments {
  .users-page {
    margin-bottom: 0;
  }

  .title-table {
    padding-top: 5%;
    font-size: 30px;
    font-weight: 600;
    color: $color-black;
  }

  .white-table {

    margin: 3em 0 0 0;

    .MuiTypography-root {
      color: inherit;
      font-size: 14px;
      font-weight: 400;
      padding: 0;
      font-family: Montserrat, sans-serif;
      margin: 0;
      line-height: 1.66;
      letter-spacing: 0.03333em;
    }
  }

}
