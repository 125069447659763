.custom-clipboard{
  //margin-bottom: 1em;
  display: flex;
  padding: 0 30px;
  align-items: center;

  .ref-code{
    font-family: Montserrat;
    border: none;
    background-color: #f1f4f7;
    padding: 1.3em;
    color: #000000;
    font-size: 15px;
    width: 250px;
  }
  .copy-button{
    position: relative;
    //top: 1.7em;
    //width: 4.2em;
    //height: 4.3em;
    width: 56px;
    height: 56px;
    background: url("../../../styles/images/copyToclipboard.svg") #505bda center no-repeat;
    border: none;
    cursor: pointer;
  }
  .copied{
    border-radius: 50px;
    color: #f1f4f7;
    margin: auto 2em;
    //height: 2.3em;
    //height: 2.3em;
    //padding: 5px;
    font-weight: 600;
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    background-color: #505bda;
  }
  .invisible{
    display: none;
  }
}
