@import "../../../../../styles/variables";
header {
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
  background-color: $color-white;

  .nav-item {
    margin-left: 2em;
    color: #646a75;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: flex;
    min-width: 120px;
    justify-content: center;
    align-items: center;
    &:hover{
      background-color: #edeefb;
      text-align: center;
    }
  }
  .selected {
    color: $color-violet;
    border-bottom: 4px solid $color-violet;
    background-color: $color-violet-selected;
  }
  .logo{
    position: absolute;
    width: 100px;
    height: 55px;
    background: url("../../../../../styles/images/bitmap.png") center no-repeat;
  }
  .flex-wrapper{
    margin: 0 115px 0 115px;
    display: flex;
    justify-content: space-between;
    height: 3.5em;
    //min-width: 1480px;
  }
  .nav-flex-wrapper{
    width: 30%;
    display: flex;
    justify-content: space-between;
  }

  img {
    max-width: 76px;
    max-height: 32px;
  }
  .menu-items {
    display: flex;
    flex-direction: row;
    padding-left: 40%;
    a {
      text-decoration: none;
      span {
        margin: 0 10px 0 10px;
        font-size: 16px;
        font-weight: 500;
        color: #515151;
      }
    }
  }
}
