@import 'https://code.highcharts.com/css/highcharts.css';

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('styles/fonts/Montserrat-Regular.ttf') format('ttf'); /* IE9 Compat Modes */
  src: local('Montserrat');
}

html, body {
  /*height: 100%;*/
  margin: auto;
  /*height: 100%;*/
  width: 100vw;
  min-width: 1024px;
  color: #484356;
  background-color: #f7f8fa;
}

:focus{
  outline: none;
}

#root {
  /*height: 100%;*/
  width: 100%;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1 0 auto;
  width: 100%;
}

footer {
  flex-shrink: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*TODO: uncomment after adding grey table*/
/*.highcharts-background {*/
/*  fill: #e8ecf1;*/
/*}*/

.highcharts-title {
  margin: 1rem;
  font-weight: 500;
  font-size: 20px !important;
}

.highcharts-credits{
  display: none
}
