@import "../../../../styles/variables";
.telegram-code{
  display: flex;
  //justify-content: center;
  .users-page {
    margin-bottom: 0;
  }
  .title-block{
    font-size: 20px;
    margin: auto 0px;
    margin-right: 50px !important;
    font-weight: 400;
    color: $color-black;
  }

  .white-table{
    margin: unset;
    margin-top: 3em;
  }
}
