.stats {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  //grid-template-columns: repeat(auto-fill, 1fr);
  //grid-template-columns: 1fr fit-content(20%);
  grid-auto-rows: 1fr;
  grid-gap: 20px;


  .green {
    color: green;
  }

  .trade {
    padding: 1em;
    margin-top: 0.5em;
    text-align: center;
    letter-spacing: 1px;
    color: #505bda;
    font-size: 28px;
    font-weight: 600;

    div {
      margin-top: 0.3em;
      margin-bottom: -0.95em;
      color: #646a75;
      font-size: 18px;
      font-weight: 500;
    }
  ;
  }
}

.navlink {
  text-decoration: none;

  &:visited {
    color: #505bda;
  }
}
