@import "../../../../../../../styles/variables";

.referees {
  background-color: white;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  .pair-field {
    width: 60%;
  }

  .container {
    padding-top: 3em;
    background-color: $color-white;
    bottom: 3px;
    position: relative;
  }

  &__white-block {
    background-color: $color-white;
    padding: 3em;
    padding-top: 0;
    //padding: 0 3em;
    position: relative;
    bottom: 2px;

    .MuiPaper-root {
      box-shadow: none;
    }

    .MuiTableCell-root {
      //border: none!important;
      padding: 14px;
      font-family: Montserrat, sans-serif;
      line-height: 1.43;
      font-size: 14px;
    }

    .MuiToolbar-root {
      padding-right: 30px;
      padding-left: 30px;
    }

    .MuiTypography-root {
      color: #000000;
      font-size: 26px;
      font-weight: 600;
      padding: 30px 0;
      font-family: Montserrat, sans-serif;
    }

    .MuiSelect-selectMenu {
      width: 12em;

      &:after {
        content: '';
        width: 8em;
        height: 1em;
        left: 5em;
        background: url("../../../../../../../styles/images/bold-little-arrow-down.png") center no-repeat;
        position: absolute;
      }
    }

    .MuiFormHelperText-root {
      margin: 8px;
    }

    .MuiFormControl-marginNormal {
      margin-top: 0;
    }

    .MuiFormControlLabel-root {
      margin-right: 68px;
    }

    .MuiFormGroup-row {
      padding-bottom: 1em;
    }

    .MuiTypography-body1 {
      font-family: Montserrat;
      color: $color-black;
      font-weight: 600;
      font-size: 16px;
    }

    //.MuiInputBase-input{
    //  background-color: #f1f4f7;
    //  padding: 1em;
    //  font-size: 16px;
    //  margin-right: 2em;
    //  margin-top: 1em;
    //}
    .MuiInputLabel-formControl {
      transform: none;
    }

    .MuiInputLabel-root {
      color: black;
      font-size: 16px;
      font-family: Montserrat;
      padding-left: 1em;
      font-weight: 600;
    }

    .MuiInput-underline {
      &:before {
        border-bottom: none !important;
      }

      &:after {
        margin-right: 2em;
        border-bottom: 2px solid $color-violet;
      }
    }
  }

  .title-user {
    padding: 2em 0;
    font-weight: 600;
    color: #000000;
  }

  h2 {
    font-size: 16px;
    letter-spacing: 0.7px;
    color: #000000;
  }

  .table-title {
    padding-left: 3em;
  }

  //.flex-container {
  //  display: flex;
  //  justify-content: space-between;
  //}
  //
  //.flex-item {
  //  width: 50%;
  //}

  .submit-btn {
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 3px;
    font-size: 18px;
    cursor: pointer;
    font-family: Montserrat;
    background-color: $color-violet;
    color: $color-white;
    font-weight: 600;

    &:hover {
      background-color: $color-hover-btn;
      transition-duration: 0.5s;
    }
  }

  .flex-end {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .flex-start {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .page-content {
    .white-block {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
  }

  .users-page .page-content {
    margin: 0;
    margin-bottom: 0;
    //display: flex;
    //flex-direction: column;
  }


}
