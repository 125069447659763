.user-transactions-tab {
  background-color: #ffffff;
  padding: 3em;
  padding-top: 0;
  position: relative;
  bottom: 2px;
  box-shadow: 0 4px 6px 0 rgba(0,0,0,0.11);
  .page-content{
    margin: 0;
    .white-table{
      margin: 3em 0 0 0;
      .MuiTypography-root {
        font-size: inherit;
        padding: inherit;
      }
    }
  }
  .users-page {
    margin-bottom: unset;
  }
}
