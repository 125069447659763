@import "../../../../styles/variables";

.user-referees {
  .title-block {
    padding: 30px;
    font-size: 26px;
    font-weight: 600;
    color: $color-black;
    line-height: 30px;
  }
  .last-update {
    //margin-bottom: 20px;
    display: flex;
    font-size: 20px;

    .margin-none {
      //padding: 0 1.5em 1.5em 0 !important;
      margin-top: 2px;
    }

    span {
      font-weight: 800;
    }

    &_btn {
      margin: auto auto auto 10px;
      height: 30px;
      width: 30px;
      fill: #7179d6;
    }

    &_btn:hover {
      cursor: pointer;
      fill: #404dd7;
    }
  }
  .title {
    color: $color-black;
    font-size: 26px;
    font-weight: 700;
    //padding: 2em 1.35em;
    //padding: 1em 1em 0em 1em;
    padding: 30px 30px 0em 30px;

    //color: #02C076;
  }

  &__overview {
    display: flex;
  }

  &__block {
    //background-color: $color-trader;
    font-size: 18px;
    color: $color-black;
    text-align: center;
    //padding: 5% 2% 2%;
    //padding: 2%;
    padding: 30px;
    //@media screen and (max-width: 1635px) {
    //  margin-right: 3em;
    //  width: 17%;
    //  padding: 2%;
    //}


    display: flex;
    flex-direction: column;
    justify-content: center;


    &__title {
      font-size: 18px;
      font-weight: 500;
      //line-height: 40px;
      margin-bottom: 10px;
      color: $color-dark-gray;
    }

    &__data {
      font-size: 30px;
      font-weight: 600;
    }
  }

  .referral-code-block {
    display: flex;
    //justify-content: space-between;
  }

  .white-block {
    background-color: $color-white;
    //padding: 3em;
    margin-top: 3em;
    position: relative;
    bottom: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

    h2 {
      font-size: 16px;
      color: $color-black;
      font-family: Montserrat;
      letter-spacing: 1px;
      margin-bottom: 0;
    }
  }

  .MTableHeader-header-112 {
    background-color: $color-light-gray;
  }

  .MuiPaper-elevation2-9 {

    box-shadow: none;
    //padding-left: 48px!important;
    //max-width: 50%!important;
  }

  .MTableToolbar-root-32 {
    padding-left: 30px;
    margin-top: 10px;

    h6 {
      font-weight: 600;
      font-size: 26px;
      font-family: Montserrat;
    }
  }

  &__spinner {
    //position: relative!important;
    //margin-left: -48px;
    //position: absolute;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    width: 100%;
    //min-height: unset;
    //background-color: rgba(255, 255, 255, 0.75);
    //z-index: 1;
    height: 100%;


    img {
      display: none;
    }
  }
  .MuiTableRow-root {
    &:nth-child(even) {
      background-color: #eff1f35c;
    }
  }

}
