@import "../../../../styles/variables";

.users-page {
  margin-bottom: 2em;

  .users-table-subpage {

    .last-update {
      //margin-bottom: 20px;
      display: flex;
      font-size: 20px;

      span {
        font-weight: 800;
      }

      .margin-none {
        //padding: 0 1.5em 1.5em 0 !important;
        margin-top: 2px;
      }

      &_btn {
        margin: auto auto auto 10px;
        height: 30px;
        width: 30px;
        fill: #7179d6;
      }

      &_btn:hover {
        cursor: pointer;
        fill: #404dd7;
      }
    }

    hr {
      margin: 0;
    }
  }

  .subheader-new-user {
    //padding: 50px 0 20px;
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    .subtitle {
      margin-top: 1%;
      font-weight: 600;
      font-size: 30px;
      color: #000000;
      min-width: 200px;
    }

    .subhead_title {
      padding: 50px 0;
      font-size: 30px;
      color: #000000;

      &:after {
        content: 'fd';
        color: transparent;
        width: 30px;
        height: 30px;
        z-index: 40;
        background: url("../../../../styles/images/right-arrow.png");
      }
    }

    .btn-create {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
      height: 45px;
      background-color: #ffffff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-left: 2rem;
      padding: 0 1em;

      span {
        font-size: 15px;
        font-weight: 600;
        color: $color-violet;
      }
    }
  }

  .close-btn {
    width: 27px;
    height: 20px;
    background: url("../../../../styles/images/close-icn.png") 90% 55%;
    border: none;
    cursor: pointer;
  }

  .nav-title {
    border: none;
    background: transparent;
    box-shadow: none;
    color: black;
    min-width: 120px;
    text-decoration: none;
    font-size: 26px;
    font-weight: bold;
    display: flex;
    align-items: center;

    &:after {
      content: '';
      width: 40px;
      height: 40px;
      background: url("../../../../styles/images/right-arrow.png");
    }
  }

  .subheader {
    //margin: 0 115px 0 115px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    //margin: 4% 0 4% 0;
    margin: 1.5rem 0;

    span {
      background: transparent;
      border: none;
      box-shadow: none;
      text-decoration: none;
      margin: 2% 0;
    }
    label {
      font-size: 30px;
      font-weight: bold;
      color: #000000;
    }

    a {
      button {
        cursor: pointer;
        width: 200px;
        height: 45px;
        background: url("../../../../styles/images/new-user.png") no-repeat 5% #ffffff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        color: $color-violet;
        padding-left: 20px;
        font-weight: 600;
        border: none;
        font-size: 15px;

        span {
          margin: 12.5px 31px 12.5px 21px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 500;
          color: #515151;

          svg {
            margin: 0;
            width: 20px;
            height: 19px;
          }
        }
      }
    }
  }

  hr {
    margin: 0 115px 0 115px;
    opacity: 0.2;
    border: solid 1px #979797;
  }

  .page-content {
    margin: 0 115px 0 115px;
    display: flex;
    flex-direction: column;
  }
}

.trader-summary {
  width: 100%;
  //display: flex;
  //flex-wrap: wrap;
  //display: grid;
  //grid-template-areas: 'a a';
  //grid-gap: 10px;
  //grid-template-columns: repeat(auto-fill, 220px);
  //grid-auto-rows: 120px;
  //margin: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 20px;


  //justify-content: space-around;


  //margin-right: 5%;
  //@media screen and (max-width: 1635px) {
  //  display: none;
  //}

  &__block {
    //background-color: $color-trader;
    font-size: 18px;
    color: $color-black;
    text-align: center;
    //padding: 5% 2% 2%;
    //padding: 2%;
    //padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    //@media screen and (max-width: 1635px) {
    //  margin-right: 3em;
    //  width: 17%;
    //  padding: 2%;
    //}

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 40px;
      color: $color-dark-gray;
      text-transform: capitalize;
    }
  }

}
