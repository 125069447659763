@import "../../../../styles/variables";

.home {

  .highcharts-tooltip {
    .highcharts-label-box {
      fill: white !important;
    }
  }

  .highcharts-navigator-mask-inside {
    fill: #e8ecf1 !important;
    fill-opacity: 0.5 !important;
  }

  .highcharts-scrollbar-thumb {
    fill: #e8ecf1 !important;
    fill-opacity: 1 !important;
  }

  .no-data {
    color: red;
  }

  .portfolio {
    position: absolute;
    z-index: 6;
    top: 1em;
    left: 2em;
    letter-spacing: 0.5px;
    color: #646a75;
    font-size: 16px;

    .portfolio-regular {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.7px;
      color: #646a75;
      margin-right: 1em;
    }

    .portfolio-main {
      color: $color-black;
      font-size: 24px;
      font-weight: 600;
      letter-spacing: 1.09px;
      margin-right: 1em;
    }
  }

  .white-container {
    background-color: $color-white;
    position: relative;
    bottom: 2px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
    margin-top: 3em;

    .chart-container {
      border-bottom: solid 1px #c6cacf;
    }
  }

  .widgets-container {
    display: flex;
    //border-right: solid 1px #c6cacf;
    padding: 2em;
    .widget {



      //@media screen and (max-width: 1315px) {
      //  width: 171px;
      //  padding: 0.5em;
      //  margin: 2em 0.5em;
      //}
      //
      //width: 209px;
      //border-right: solid 1px #c6cacf;
      //padding: 1em;
      //margin: 2em;

      .value-container {
        margin-top: 6%;
        display: flex;
        vertical-align: bottom;
        justify-items: end;
        justify-content: unset;
        align-items: baseline;

        .value {
          margin-left: 0.2em;
          font-size: 35px;
          font-weight: 600;
          letter-spacing: 1px;
          color: $color-black;
        }

        .percent {
          font-size: 18px;
          color: $color-value;
          margin-left: 7%;
        }
      }

      .yellow {
        color: $color-yellow;
        font-size: 20px;

        &:before {
          content: 'gh';
          color: transparent;
          width: 20px;
          height: 20px;
          margin-right: 4%;
          background: url("../../../../styles/images/bitcoin.png") center no-repeat;
        }
      }

      .blue {
        color: $color-blue;
        font-size: 20px;

        &:before {
          content: 'gh';
          color: transparent;
          width: 20px;
          height: 20px;
          margin-right: 4%;
          background: url("../../../../styles/images/ethereum-eth-icon.png") center no-repeat;
        }
      }
    }
  }
}
