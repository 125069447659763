@import "../../../../../../styles/variables";
.border-form{
  padding: 1em 0 1em 1em;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
}
.create-user-form {
  background-color: $color-white;
  .flex-column{
    display: flex;
    flex-direction: column;
  }
  form {
    display: flex;
    width: 100%;
    .form-container{
      width: 100%;
    }
    .input-box {
      margin-bottom: 32px;
      label{
        font-size: 16px;
        color: #000000;
        font-weight: 600;
      }
      input{
        font-family: Montserrat;
        font-style: normal;
        margin-right: 3em;
      }
    }
    .error-block{
      width: 100%;
      text-align: center;
      color: red;
      padding-top: 0.2em;
    }
    .btn-block{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .cancel-btn{
        margin-top: 2em;
        display: inline-block;
        height: 46px;
        background: #ffffff;
        padding: 0 2em;
        border: solid 1.5px #505bda;
        text-decoration: none;
        color: #505bda;
        letter-spacing: 1px;
        text-align: center;
        font-size: 22px;
        display: flex;
        align-items: center;
      }
      .submit-btn {
        font-family: Montserrat;
        margin-top: -2px;
        height: 47px;
        background-color: $color-violet;
        border: 0;
        padding: 0.5em 2em;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 0.82px;
        color: $color-white;
        border-radius: 0;
        text-transform: none;
        cursor: pointer;
        &:hover{
          background-color: $color-hover-btn;
          transition-duration: 0.2s;
        }
      }
    }
  }
}
