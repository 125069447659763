@import "../../../../../../../../../styles/variables";

.user-settings-tab {

  .white-block {
    background-color: $color-white;
    padding: 3em;
    position: relative;
    bottom: 2px;
  }

  .basic-field {
    min-width: 250px;
    flex: 1;

    &__base {
      min-width: 150px;
    }

    &__alt {
      min-width: 150px;
    }
  }


  .trading-pairs-title {
    color: #000000;
    font-size: 26px;
    font-weight: 700;
    padding: 2rem 0;
  }

  .trading-pairs {
    grid-gap: 1rem;
    display: flex;
    flex-direction: column;
  }

  .pair-field {
    display: flex;
    gap: 10px;
    max-width: 700px;

    &__btns {
      display: flex;
      align-items: center;
      margin-top: 8px;
    }

    .MuiTextField-root {
      //width: 100%;
      margin-top: 16px;
      margin-bottom: 8px;
    }

    //
    //.MuiInputBase-input {
    //  background-color: #f1f4f7;
    //  padding: 1em;
    //  font-size: 16px;
    //  min-width: 45px;
    //  margin-top: 10px;
    //}
    //
    //.MuiInputLabel-root {
    //  color: black;
    //  font-size: 16px;
    //  font-family: Montserrat;
    //  font-weight: 600;
    //}
  }

  //.MuiAutocomplete-root{
  //  width: 100%;
  //  .MuiInput-root
  //  .MuiInput-input{
  //    padding: 16px;
  //  }
  //
  //  .MuiAutocomplete-inputRoot {
  //    margin-right: 30px;
  //  }
  //        /* margin: 10px 0; */
  //}

  .flex-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .user-basic-field {
    width: 25%;
    min-width: 200px;
  }

  .submit-btn {
    width: 200px;
    height: 50px;
    border: none;
    border-radius: 3px;
    font-size: 18px;
    cursor: pointer;
    font-family: Montserrat;
    background-color: $color-violet;
    color: $color-white;
    font-weight: 600;

    &:hover {
      background-color: $color-hover-btn;
      transition-duration: 0.5s;
    }
  }

  .btn {
    width: 34px;
    height: 34px;
    border: 2px solid $color-black;
    //margin-left: 1em;
    //margin-top: 20px;
    cursor: pointer;
    margin: 10px 0 10px 10px;

    &:hover {
      border: 2px solid $color-violet;
    }
  }

  .btn-plus {
    background: url("../../../../../../../../../styles/images/plus.svg") center no-repeat;

    &:hover {
      background: url("../../../../../../../../../styles/images/plus-violet.svg") center no-repeat;
    }
  }

  .btn-minus {
    background: url("../../../../../../../../../styles/images/minus.svg") center no-repeat;

    &:hover {
      background: url("../../../../../../../../../styles/images/minus-violet.svg") center no-repeat;
    }
  }

  .add-new {
    width: fit-content;
  }

}
