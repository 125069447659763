@import "../../../../../../../styles/variables";

.login-form {
  .error-message{
    text-align: center;
    color: red;
  }
  width: 660px;
  height: 659px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  .welcome {
    padding-top: 3em;
    width: 660px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      height: 26px;
      font-size: 34px;
      font-weight: bold;
      color: $color-black;
      &:before {
        content: 'jjkjk';
        color: transparent;
        width: 38px;
        height: 26px;
        display: block;
        background-image: url("../../../../../../../styles/images/hat.png");
        position: relative;
        z-index: 67;
      }
    }
  }
  form {
    margin: 5% 12%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    .input-box {
      margin-bottom: 32px;
      input {
        font-family: Montserrat, sans-serif;
        font-style: normal;
        background-color: $color-main;
      }
    }
    .forgot-password {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
      a {
        text-decoration: none;
        span {
          font-size: 16px;
          letter-spacing: 0.73px;
          text-align: right;
          color: $color-black;
          font-weight: 500;
        }
      }
    }
    .button {
      height: 50px;
      background-color: $color-violet;
      border: 0;
      width: 100%;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 1px;
      color: $color-white;
      border-radius: 0;
      text-transform: none;
      cursor: pointer;
      font-family: Montserrat;
      &:hover {
        background-color: $color-main;
        color: black;
        transition-duration: 0.2s;
      }
    }
    .sign-up {
      font-family: Montserrat;
      height: 50px;
      background-color: $color-white;
      border: 0;
      width: 100%;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 1px;
      color: $color-violet;
      border-radius: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      &:hover {
        background-color: #E7E9EB;
        transition-duration: 0.2s;
      }
    }
  }
}
