@import "../../../../../../../styles/variables";

.tab-panel {
  margin-bottom: 3.5rem;

  &__trader-diary {
    //margin: unset;
    .page-content {
      margin: unset;
    }

    .users-page {
      margin: unset;
    }

    .trader-diary {


    }

    .white-block {
      padding: 0 3em !important;
    }
  }

  hr {
    margin: 1% 0 4% 0 !important;
  }

  .nav-bar {
    //height: 6em;
    display: flex;
  }

  .nav-bar-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 15%;
    line-height: 30px;
    border: none;
    background: transparent;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    color: $color-black;
    text-align: center;
    border-top: 4px solid transparent;
    background-size: 40px 40px !important;

    &:before {
      content: '';
      height: 30px;
    }

    &:after {
      content: '';
      height: 5px;
    }
  }

  .ref {
    background: url("../../../../../../../styles/images/ref.png") center top no-repeat;
  }

  .stat {
    background: url("../../../../../../../styles/images/stat.png") center top no-repeat;
  }

  .user-settings {
    background: url("../../../../../../../styles/images/settings-black.svg") center top no-repeat;
  }

  .trad-settings {
    background: url("../../../../../../../styles/images/t-settings.png") center top no-repeat;
  }

  .transact {
    background: url("../../../../../../../styles/images/transact.png") center top no-repeat;
  }

  .activity {
    background: url("../../../../../../../styles/images/activity.png") center top no-repeat;
  }

  .ref-active {
    background: url("../../../../../../../styles/images/ref-violet.svg") center top no-repeat;
  }

  .stat-active {
    background: url("../../../../../../../styles/images/stat-violet.svg") center top no-repeat;
  }

  .user-settings-active {
    background: url("../../../../../../../styles/images/settings-violet.svg") center top no-repeat;
  }

  .trad-settings-active {
    background: url("../../../../../../../styles/images/t-settings-violet.svg") center top no-repeat;
  }

  .transact-active {
    background: url("../../../../../../../styles/images/transact-violet.svg") center top no-repeat;
  }

  .activity-active {
    background: url("../../../../../../../styles/images/activity-violet.svg") center top no-repeat;
  }

  .img {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }

  .active {
    background-color: $color-white;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
    border-top: 4px solid $color-violet;
    color: $color-violet;
    font-weight: 600;
  }

  .MuiAppBar-colorDefault {
    height: 6em;
    background-color: #F3F3F3;
  }

  .MuiPaper-elevation4 {
    box-shadow: none;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: rgba(0, 0, 0, 0.54);
  }

  .subhead-flex {
    display: flex;
    width: 100%;
  }

  .margin-btn {
    margin-top: 1em !important;
  }

  .white-block {
    background-color: $color-white;
    position: relative;
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.11);
    bottom: 2px;

    h2 {
      font-size: 16px;
      color: $color-black;
      font-family: Montserrat;
      letter-spacing: 1px;
      margin-bottom: 0;
    }
  }
}
