.user-activity-tab {
  position: relative;
  bottom: 2px;
  background-color: white;
  //margin: unset;
  //padding: unset;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.11);
  .page-content{
    padding: 0 3em;
    margin: 0;
  }
  .white-table{
    margin: 3em 0;
  }

}
