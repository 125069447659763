@import "../../../../styles/variables";


.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: unset;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1;
  height: 400px;
}

.trader-diary {
  width: 100%;

  .margin-none {
    //padding: 0 1.5em 1.5em 0 !important;
    //margin-top: 3px;
  }

  .MTableHeader-header-225 {
    background-color: #f1f4f7;
    border: none;
  }

  .MTableHeader-header-120 {
    background-color: #f1f4f7;
    border: none;
  }

  .MuiTableCell-root {
    border: none;
    padding: 14px;
    font-family: Montserrat, sans-serif;
    line-height: 1.43;
    font-size: 14px;
  }

  .MuiToolbar-gutters-674 {
    padding-left: 30px;

  }

  .MuiTableRow-root-114 {
    //border-bottom: 1px solid rgba(224, 224, 224, 1);

    .MuiPaper-elevation2-9 {
      border: none;
      padding-left: 48px !important;
      max-width: 57% !important;
      //width: 700px
    }

  }

  .MuiTableHead-root-113 {
    background-color: rgb(241, 244, 247);
  }

  .MTableHeader-header-112 {
    background-color: inherit;
  }

  .MuiTableRow-root {
    &:nth-child(even) {
      background-color: #eff1f35c;
    }
  }

  .MuiPaper-elevation2-216 {
    box-shadow: none !important;
  }

  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }

  .MuiPaper-elevation2-9 {
    box-shadow: none !important;
  }

  //.MuiFormControl-root {
  //  //background-color: #f1f4f7;
  //  color: #646a75;
  //  font-family: Montserrat !important;
  //  height: 2.5em;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //}

  .MuiIconButton-colorInherit {
    //background-color: #f1f4f7 !important;
    border-radius: 0 !important;
    padding: 8px !important;
    margin-left: 1em !important;
    margin-right: 1em !important;
  }

  .MuiInput-underline:before {
    border: none !important;
  }

  .MTableToolbar-searchField-38 {
    padding-left: 0 !important;
  }

  .MuiInputBase-input {
    margin-left: 1em !important;
  }

  .subtitle {
    color: $color-black;
    font-size: 26px;
    font-weight: 600;
    padding: 30px 30px 0em 30px;
    //padding-top: 30px;
    //position: relative;
    //z-index: 100;
    //top: 30px;
    //width: 22%;
  }

  .title {
    color: $color-black;
    font-size: 26px;
    font-weight: 700;
    //padding: 2em 1.35em;
    //padding: 1em 1em 0em 1em;
    padding: 30px 30px 0em 30px;

    //color: #02C076;
  }

  .deals {
    height: 2.4em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiIconButton-root{
    padding: unset!important;
  }
  .white-table {
    margin: 3em 0;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;

    .MuiToolbar-root{
      padding-right: 30px;
      padding-left: 30px;
    }


    .MuiTypography-root{
      color: #000000;
      font-size: 26px;
      font-weight: 600;
      padding: 30px 0;
      font-family: Montserrat, sans-serif;
    }
  }

  .block-white {
    margin-top: 3em;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    //padding: 2em;
    .chart-container {
      //box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      //@media screen and (max-width: 1635px) {
      //  width: 100%;
      //}
      //width: 90%;
    }

    .chart-wrapper {
      display: flex;
      flex-direction: column;
      //@media screen and (max-width: 1635px) {
      //  flex-direction: column;
      //}
    }

    .container-flex {
      display: flex;
      justify-content: space-between;
      margin-top: 8%;
      @media screen and (max-width: 1400px) {
        flex-direction: column;
      }
    }

    .container-little {
      width: 40%;
    }

    .media-container {
      @media screen and (min-width: 1636px) {
        display: none;
      }
      margin-top: 3%;
      display: flex;
      justify-content: center;
    }

    .data-container {
      width: 25%;
      margin-right: 5%;
      @media screen and (max-width: 1635px) {
        display: none;
      }
    }

    //.gray-block {
    //  background-color: $color-trader;
    //  font-size: 18px;
    //  color: $color-black;
    //  text-align: center;
    //  padding: 5% 2% 2%;
    //  @media screen and (max-width: 1635px) {
    //    margin-right: 3em;
    //    width: 17%;
    //    padding: 2%;
    //  }
    //}

    .white-block {
      margin-top: 1.4em;
      background-color: $color-white;
      padding: 10%;
      color: $color-black;
      font-weight: 600;
    }

    .padding-block {
      padding: 16%;
    }
  }

  .highcharts-tooltip {
    .highcharts-label-box {
      fill: white !important;
    }
  }

  .highcharts-navigator-mask-inside {
    fill: #e8ecf1 !important;
    fill-opacity: 0.5 !important;
  }

  .highcharts-scrollbar-thumb {
    fill: #e8ecf1 !important;
    fill-opacity: 1 !important;
  }

  //
  //.highcharts-navigator-handle {
  //  stroke: black !important;
  //  fill: black !important;
  //}
  .opened-deals {
    margin-bottom: 30px;

    .MTableToolbar-root-32 {
      display: none !important;
    }
  }

}

.trader-summary {
  width: 100%;
  //display: flex;
  //flex-wrap: wrap;
  //display: grid;
  //grid-template-areas: 'a a';
  //grid-gap: 10px;
  //grid-template-columns: repeat(auto-fill, 220px);
  //grid-auto-rows: 120px;
  //margin: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-auto-rows: 1fr;
  grid-gap: 20px;


  //justify-content: space-around;


  //margin-right: 5%;
  //@media screen and (max-width: 1635px) {
  //  display: none;
  //}

  &__block {
    //background-color: $color-trader;
    font-size: 18px;
    color: $color-black;
    text-align: center;
    //padding: 5% 2% 2%;
    //padding: 2%;
    //padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
    //@media screen and (max-width: 1635px) {
    //  margin-right: 3em;
    //  width: 17%;
    //  padding: 2%;
    //}

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 40px;
      color: $color-dark-gray;
      text-transform: capitalize;
    }

    &__data {
      font-size: 30px;
      font-weight: 600;
    }
  }

}

.green {
  color: #02C076;
}

.yellow {
  color: #F0B90B;
}

