.trader-diary .subtitle {
  padding: 30px;
}

.details-table {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
}

.details-pagination {
  display: flex!important;
  justify-content: center!important;
}

.details-toolbar {
  width: 30%!important;
}
