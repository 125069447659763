@import "../../../../styles/variables";

.new-order {
  //background: rgba(247, 248, 250, 0.8);
  background: rgba(239, 241, 243, 0.8);

  padding: 10px;

  .spinner {
    width: calc(100% - 50px);
    height: 370px;
  }

  &__types {
    padding: 10px;
    width: 100%;
    display: flex;
  }

  &__type-item {
    font-size: 20px;
    font-weight: 500;
    margin-right: 10px;
    cursor: pointer;

  }

  .active-item {
    font-weight: 600;
    color: black;
    border-bottom: 2px solid #3f51b5;

    &:hover {
      cursor: pointer;
    }
  }

  &__sides {
    display: flex;
  }


  &__buy {
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;


  }

  .order-available {
    display: flex;
    margin: 5px 0;

    &__label {
      width: 100px;
      margin: auto auto auto 10px;
    }

    &__amount {
      width: 100%;
      text-align: right;
      //margin-right: 5px;
    }

    &__currency {
      display: flex;
      width: 70px;
      margin: auto 10px auto auto;
      justify-content: flex-end;

    }
  }

  .order-price {
    margin: 5px 0;
    display: flex;
    //background-color: #f1f4f7;
    background-color: white;;
    //margin-bottom: 2px;
    //padding-bottom: 2px;
    box-sizing: border-box;
    //border-bottom: 2px solid #f1f4f7;

    .MuiInput-input {
      text-align: right;
    }


    //&:focus-within {
    //  border-bottom: 2px solid $color-violet;
    //}
  }

  .buy-btn {
    margin: 16px 0px 0px;
    appearance: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 6px 12px;
    min-width: 52px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    word-break: keep-all;
    min-height: 24px;
    background-color: rgba(14, 203, 129, 0.7);
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: white;
    &:disabled {
      background-color: rgba(239, 239, 239, 0.7);
      color: rgba(16, 16, 16, 0.3);
      border-color: rgba(118, 118, 118, 0.5);
      cursor: not-allowed;
      &:hover{
        background-color: rgba(239, 239, 239, 0.8);
      }
    }
    &:hover{
      background-color: rgba(14, 203, 129, 0.8);
    }

  }

  .sell-btn {
    margin: 16px 0px 0px;
    appearance: none;
    user-select: none;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 6px 12px;
    min-width: 52px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    word-break: keep-all;
    min-height: 24px;
    background-color: rgba(246, 70, 93, 0.7);
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    color: white;
    &:disabled {
      background-color: rgba(246, 70, 93, 0.7);
      color: rgba(16, 16, 16, 0.3);
      border-color: rgba(118, 118, 118, 0.5);
      cursor: not-allowed;
      &:hover{
        background-color: rgba(246, 70, 93, 0.8);
      }
    }
    &:hover{
      background-color: rgba(246, 70, 93, 0.8);
    }

  }

  //.order-slider{
  .Mui-disabled{
    .MuiSlider-mark{
      border-color: #bdbdbd;
    }
  }
  .MuiSlider-root {
    zoom: 1.33;
    padding: 5px 0;
    margin-top: 10px;
    margin-bottom: 10px;

    margin-left: 10px;
    width: calc(100% - 20px);

    //.MuiSlider-valueLabel {
    //  font-size: 12px;
    //  font-weight: normal;
    //  top: -6;
    //  background-color: red;
    //  color: black;
    //
    //  &:before {
    //    display: none
    //  }
    //
    //  &:after {
    //    background: transparent;
    //    color: black;
    //  }
    //}
  }

  .order-label {
    display: flex;
    width: 100px;
    margin: auto auto auto 10px;
  }

  .order-amount {
    text-align: end;
    color: black;

    //font-weight: 600;
  }

  .MuiIconButton-root {
    padding: unset !important;
  }

  .order-currency {
    display: flex;
    width: 70px;
    margin: auto 10px auto auto;
    justify-content: flex-end;
    //font-weight: 600;
    //color: black;
  }

  &__sell {
    padding: 10px;
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .MuiFormHelperText-root {
    margin: 8px;
  }

  .MuiFormControl-marginNormal {
    margin-top: 0;
  }


  .MuiFormGroup-row {
    padding-bottom: 1em;
  }

  .MuiTypography-body1 {
    font-family: Montserrat;
    color: $color-black;
    font-weight: 600;
    font-size: 16px;
  }

  .MuiSelect-icon {
    display: none !important;
  }

  .MuiInputLabel-formControl {
    //background-color: #f1f4f7;
  }

  //.MuiFormControl-root {
  //  height: unset;
  //  margin: 0;
  //}

  .MuiInput-formControl {
    margin-top: 0;
  }

  .MuiInputBase-input {
    //background-color: #f1f4f7;
    //padding: 1em;
    font-size: 16px;
    //margin-right: 2em;
    margin: 0 !important;
    min-width: 45px;
    //margin-top: 25px !important;
    padding: 10px 0;

    //margin-right: 16px;
  }

  .MuiSlider-mark {
    width: 4px;
    height: 4px;
    // top: 12px;
    background: white;
    border-radius: 50%;
    border: 2px solid #1976d2;
  }

  .MuiSlider-markActive {
    opacity: 1;
    background: #1976d2;
    border-color: white;
  }

  .MuiInputLabel-formControl {
    transform: none;
  }

  .MuiInputLabel-root {
    color: black;
    font-size: 16px;
    font-family: Montserrat;
    //padding-left: 1em;
    font-weight: 600;
  }

  .MuiInput-underline {
    &:before {
      border-bottom: none !important;
    }

    &:after {
      //margin-right: -65px;
      //margin-left: -85px;
      //border-bottom: 2px solid $color-violet;
      border-bottom: none !important;
    }
  }


}
