@import "../../../../../styles/variables";
.login-page {
  background-image: url("../../../../../styles/images/network-bg.png");
  background-color: #f3f3f3;
  header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    img {
      max-width: 153px;
      max-height: 24px;
    }
  }
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(94vh*1.35);
  }
  .link{
    margin-top: 2em;
    padding: 0.5em;
    font-size: 18px;
    color: $color-violet;
    text-decoration: none;
    border-bottom: 1px solid $color-violet;
  }
}
