@import "../../../styles/variables";
.pageable-table {
  .loading-wrapper{
    position: relative;
    z-index: 90;
    top: 70px;
    display: flex;
    align-items: flex-end;
  }
  .linear-activity {
    overflow: hidden;
    width: 100%;
    height: 4px;
    background-color: #3841a3;
  }

  .indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .indeterminate:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: #7b85fd;
    animation: indeterminate_first 2.5s infinite ease-out;
  }

  .indeterminate:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: #505bda;
    animation: indeterminate_second 2.5s infinite ease-in;
  }

  @keyframes indeterminate_first {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 90%;
    }
  }

  @keyframes indeterminate_second {
    0% {
      left: -150%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 90%;
    }
  }

  .active-status {
    //position: relative;
    //margin: auto;
    //z-index: 1;

    font-style: italic;
    font-weight: 700;
    color: #07750a;
    text-align: center;
    //width: 8px;
    //height: 8px;
    //background-color: #32d4a0;
    //border-radius: 50%;
  }
  .passive-status {
    //position: relative;
    //margin: auto;
    //z-index: 1;
    //width: 8px;
    //height: 8px;
    text-align: center;
    font-style: italic;
    font-weight: 700;
    color: #dd1c4a;
    //background-color: #fd002e;
    //border-radius: 50%;
  }
  .base-currency {
    font-weight: 600;
    color: $color-black;
  }
  .table-shadow{
    height: 950px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
  }
  .invisible{
    display: none;
  }
  .no-data{
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
    color: $color-black;
  }
.ReactTable .rt-thead.-header{
  box-shadow: none;
}
  .ReactTable{
    .rt-table{
      overflow: visible;
    }
    border: none;
    .rt-thead{
      .-header{
        box-shadow: none;
      }
      .rt-td{
        border: none;
      }
      .rt-tr{
        background-color: $color-light-gray;
        text-transform: uppercase;
        letter-spacing: 0.87px;
        color: $color-black;
        font-weight: 600;
        font-size: 13px;
        min-height: 65px;
        display: flex;
        align-items: center;
      }
      .rt-th{
        border: none;
      }
      .rt-resizable-header-content{
        text-align: left;
        padding-left: 2.5em;
      }
    }
    .rt-tr{
      align-items: center;
    }
    .rt-tbody{
      overflow: visible;
      display: unset;
      .rt-td{
        padding-left: 2%;
      }
      .rt-tr-group{
        min-height: 77px;
        color: #5d5d5d;
        font-size: 15px;
        border: none;
          &:nth-child(even){
            background-color: #f1f4f7;
          }
          &:nth-child(odd){
            background: white;
          }
          &:hover{
            position: relative;
            z-index: 66;
            margin: 0 -1em;
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.26);
            transition-duration: 0.5s;
            cursor: pointer;
          }
      }
    }
  }
}
